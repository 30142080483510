<template>
   <div>
     <div class="project_about_cont">
       <div class="container">
         <div v-if="loading" class="loading-wrap">
           <div class="lds-ellipsis">
             <div></div>
             <div></div>
             <div></div>
             <div></div>
           </div>
         </div>
         <div class="row">
           <div class="col-12 col-md-6">
             <div class="left_text" v-if="language.short == 'mn'">
               <p class="text1">{{aboutp.sub_header}}</p>
               <p class="text2_title">{{aboutp.Header}}</p>
               <p class="text3" v-html="aboutp.about"></p>
               <ul class="text_list" v-html="aboutp.servive"></ul>
             </div>

             <div class="left_text" v-else>
               <p class="text1">{{aboutp.sub_header_en}}</p>
               <p class="text2_title">{{aboutp.header_en}}</p>
               <p class="text3" v-html="aboutp.about_en"></p>
               <ul class="text_list" v-html="aboutp.servive_en"></ul>
             </div>
           </div>
           <div class="col-12 col-md-6 ">
             <div class="right_video">
               <div class="o_column">
                 <div class="survey-item">
                   <img  src="../../assets/img/survey_01.jpeg" class="survey-image" alt="building-176932_1280">
                   <div class="noo-survey-ds">
                     <a href="javascript:void(0);" @click="vide01 = true">
                       <img  src="../../assets/img/playbtn.svg" >
                     </a>
                   </div>
                 </div>
               </div>
               <div class="t_column">
                 <div class="t_column_video">
                   <div class="survey-item">
                     <img  src="../../assets/img/survey_02.jpeg" class="survey-image" alt="building-176932_1280">
                     <div class="noo-survey-ds">
                       <a href="javascript:void(0);" @click="vide02 = true">
                         <img  src="../../assets/img/playbtn.svg" >
                       </a>
                     </div>
                   </div>
                 </div>
                 <div class="t_column_video">
                   <div class="survey-item">
                     <img  src="../../assets/img/survey_03.jpeg" class="survey-image" alt="building-176932_1280">
                     <div class="noo-survey-ds">
                       <a href="javascript:void(0);" @click="vide03 = true">
                         <img  src="../../assets/img/playbtn.svg" >
                       </a>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
     <Modal
       v-model="vide01"
       :styles="{top: '25%'}"
       footer-hide>
       <div class="video_modal" v-if="aboutp.video1">
         <youtube :video-id="getVideo(aboutp.video1)"  player-width="100%"></youtube>
       </div>
     </Modal>
     <Modal
       v-model="vide02"
       :styles="{top: '25%'}"
       footer-hide>
       <div class="video_modal" v-if="aboutp.video2">
         <youtube :video-id="getVideo(aboutp.video2)"  player-width="100%"></youtube>
       </div>
     </Modal>
     <Modal
       v-model="vide03"
       :styles="{top: '25%'}"
       footer-hide>
       <div class="video_modal" v-if="aboutp.video3">
         <youtube :video-id="getVideo(aboutp.video3)"  player-width="100%"></youtube>
       </div>
     </Modal>
   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getIdFromURL } from 'vue-youtube-embed'
import { GET_ABOUT } from '../../graphql/queries'

export default {
  name: "p_about",
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ]),
  },
  data () {
    return {
      vide01: false,
      vide02: false,
      vide03: false,
      getVideo: getIdFromURL,
      aboutp: {},
      loading: true
    }
  },
  mounted () {
    this.getABOUTP();
  },
  methods: {
    getABOUTP () {
      this.loading = true
      this.$apollo.query({ query: GET_ABOUT }).then(({ data }) => {
        this.aboutp = data.aboutp[0]
        setTimeout(() => {
          this.loading = false
        })
        //console.log(this.aboutp)
      })
    },
  }
}
</script>

